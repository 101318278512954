<template>
  <div class="header-bar-wrap">
    <div class="header-bar">
      <div class="container flex flex-sb">
        <div>{{ $t('message.welcome') }}{{ $store.state.setting.websiteTitle }}
          <router-link to="/#">
            <i class="el-icon-s-home home-icon"></i>
          </router-link>
        </div>
        <div class="flex right-menu">
          <template>
<!--            <router-link to="/login" target="_blank">-->
              <i class="el-icon-s-custom color-main mg-r5"></i>
              <span class="color-main" @click="$router.push('/login')">{{ $t('message.login') }}</span>
<!--            </router-link>-->
<!--            <router-link to='/register' target="_blank">-->
              <i class="el-icon-edit-outline color-main mg-r5"></i>
              <span class="color-main" @click="$router.push('/register')">{{ $t('message.register') }}</span>
<!--            </router-link>-->
          </template>
          <router-link to='/feedback'>
            <span><i class="el-icon-edit-outline mg-r5"></i>{{
                $t('message.userfeedback')
              }}</span>
          </router-link>
          <router-link :to="`/helpCenter`" target="_blank">
            <i class="el-icon-question mg-r5"></i>
            <span>{{ $t('message.help') }}</span>
          </router-link>
          <!--<el-dropdown size="medium"
                       @command="changeLanguage"
          >
            <div class="el-dropdown-link">
              {{ $t('message.languages') }}
              <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                  v-for="language in languages"
                  :key="language.value"
                  :command="language.value"
              >
                {{ language.label }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>-->
          <!--
          <a>
            <span>{{ $t('message.serviceHotline') }}：18057109608</span>
          </a>
          -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import language from '../../resources/languages'

export default {
  name: 'GlobalHeader',
  components: {},
  data() {
    return {
      cartCount: 0,
      msgCount: 0,
      languages: [
        {label: '中文', value: 'zh-CN'},
        {label: 'English', value: 'en-US'}
      ],
    }
  },
  created() {
  },
  computed: {},
  beforeMount() {
    this.$Bus.$emit('login');
  },
  methods: {
    /**
     * 切换语言
     */
    changeLanguage(lang) {
      // this.$store.commit('setting/setLanguage', lang)
      language.loadLanguageAsync(lang)
    },
    logout() {
      // this.$store.commit('account/setUser', null);
      this.$router.push({path: '/login'});
      this.$Bus.$emit("logout");
      localStorage.removeItem("token");
    }
  }
}
</script>

<style scoped lang="scss">
.header-bar-wrap {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  height: 60px;
  font: 12px/24px "微软雅黑";
  font-weight: 400;
  line-height: 60px;

  a {
    color: #333 !important;
  }

  .header-bar {
    position: relative;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #dfdfdf;
    background-color: #f7f7f7;

    .right-menu > * {
      display: flex;
      padding: 0 13px;
      justify-content: center;
      align-items: center;
    }

    .right-menu > *:hover {
      background-color: #fff;
    }

    .right-menu img {
      margin-right: 5px;
      height: 12px;
      width: 12px;
    }
  }
}

.dot {
  min-width: 6px;
  height: 6px;
  padding: 0 3px;
  border-radius: 100px;
  line-height: 16px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  z-index: 30;
  background-color: #FA5A57;
}

.user-menu {
  position: relative;
  cursor: pointer;

  &:hover .user-menu-container {
    display: block;
  }

  .user-menu-container {
    display: none;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    position: absolute;
    left: 0;
    top: 40px;
    background: white;
    width: 240px;
    line-height: 1.2;

    .nav-warp {
      border-top: #ccc 1px dotted;
      padding: 10px;

      ::v-deep {
        .el-button {
          padding: 8px 10px;
          font-size: 10px;
        }

        .el-button + .el-button {
          margin-left: 0;
        }
      }
    }
  }
}

.el-dropdown {
  font-size: inherit;
  cursor: pointer;
}

.el-dropdown-menu--medium .el-dropdown-menu__item {
  font-size: inherit;
}

.el-dropdown-menu {
  font: 12px/24px "微软雅黑";
}

.header-bar-wrap .home-icon {
  padding-left: 5px
}
</style>
